/*----------------------------------------------------------------------------*/
/*#BACKGROUND STYLE*/
/*----------------------------------------------------------------------------*/

//default theme bg
.bg--primary { background-color: mat-color($gmb-app-primary, default); }
.bg--accent  { background-color: mat-color($gmb-app-accent, default); }
.bg--warn    { background-color: mat-color($gmb-app-warn, default); }

.bg--white { background-color: #fff; }

.bg--lightblue  { background-color: mat-color(mat-palette($gmb-blue, 50)); }
.bg--darkblue   { background-color: mat-color(mat-palette($gmb-darkblue, 500)); }
.bg--darkerblue { background-color: mat-color(mat-palette($gmb-darkblue, 900)); }

.bg--lightgreen { background-color: mat-color(mat-palette($gmb-green, 50)); }
.bg--green      { background-color: mat-color($gmb-app-accent, default); }
.bg--gray { background-color: #eeeff3; }
.bg--lightgray { background-color: #fbfbfe; }

.bg--lightyellow { background-color: mat-color(mat-palette($gmb-orange, 50)); }


.bg--register {
  background: url('assets/images/register.jpg') no-repeat center 70%/cover;
}

.bg--circles-blue { background: #f1f9ff url('assets/images/circles-blue.png') no-repeat 10px -60px/120%; }

.bg--radial-gradient-blue { background: radial-gradient(88.09% 88.09% at 50% 100%, #194881 0%, #0D1939 100%); }
