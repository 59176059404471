/*------------------------------------------*/
/*#HELPER CLASSES*/
/*------------------------------------------*/

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/*----------secondary font-------------------*/
.secondary-font {
  font-family: 'Poppins', sans-serif;
}

/*----------font weight-------------------*/
.fw--normal    { font-weight: normal!important; }
.fw--light     { font-weight: 300; }
.fw--regular   { font-weight: 400; }
.fw--medium    { font-weight: 500; }
.fw--semibold  { font-weight: 600; }
.fw--700       { font-weight: 700; }
.fw--extrabold { font-weight: 800; }
.fw--black     { font-weight: 900; }

/*----------font style------------------*/

.txt--uppercase   { text-transform: uppercase; }
.txt--lowercase   { text-transform: lowercase; }
.txt--capitalize  { text-transform: capitalize; }

.txt--overline    { text-decoration: overline; }
.txt--underline   { text-decoration: underline!important; }
.txt--linethrough { text-decoration: line-through; }

/*-----------font align -------------------*/

.txt--center  { text-align: center; }
.txt--left    { text-align: left; }
.txt--right   { text-align: right; }
.txt--justify { text-align: justify; }
.txt--start { text-align: start!important; }

/*---------elements align------------------*/

.pull--left   { float: left; }
.pull--right  { float: right; }
.pull--none   { float: none; }
.clearfix     { clear: both; }

.clearfix::before,
.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

/*--------elements visibility--------------------*/

.visible   { visibility: visible; }
.invisible { visibility: hidden; }

/*--------overflow---------------------------------*/

.overflow--hidden  { overflow: hidden; }
.overflow--visible { overflow: visible; }
.overflow--auto    { overflow: auto; }

/*--------overflow wrap---------------------------------*/

.overflow--wrap--anywhere { overflow-wrap: anywhere; }


/*--------Text Overflow---------------------------------*/
.txt-overflow-ellipsis { 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
}

/*----------------------------------------------------------------------------*/
/*#FONT COLOR*/
/*----------------------------------------------------------------------------*/

.txt--white        { color: #fff; }
.txt--dark--white  { color: #cfcdcd!important;}
.txt--yellow       { color: #FBBC02; }
.txt--yellow       { color: #E2A41F }
.txt--dark         { color: #1f304e; }
.txt--gray         { color: #575962; }
.txt--medium-gray  { color: #74797e; }
.txt--lightgray    { color: #8c91a2; }
.txt--lighter-black{ color: #1B1B1B!important; }
.txt--black        { color: #000!important; }
.txt--lightblue    { color: #249af3; }
.txt--green        { color: mat-color($gmb-app-accent, default); }
.txt--red          { color: mat-color($gmb-app-warn, default); }
.txt--orange       { color: mat-color($gmb-orange, 500); }
.txt--blue         { color: #4285F4!important; }
.txt--disbled      { color: #00000061!important; }

.txt--lightergray { color: lighten(#8c91a2, 20%); }
.txt--dark--gray { color: #737373;}

.txt--dark-black { color: #2F2F33;}

/*----------------------------------------------------------------------------*/
/*#BACKGROUND STYLE*/
/*----------------------------------------------------------------------------*/
.bg--trasnparent { background: transparent; }
.bg--white       { background: #fff; }
.bg--dark--white { background: #F5F5F7;}
.bg--yellow      { background: #FBBC02!important; }
.bg--green       { background: mat-color($gmb-app-accent, default); }
.bg--blue        { background: #4285F4; }
.bg--dark--blue  { background: #0f1520; }
.bg--red         { background: mat-color($gmb-app-warn, default); }
.bg--light-gray  { background: #eeeff3; }
.bg--pastel--gray  { background: #F4F7FC!important;}
.bg--gray        { background: #EBECEF;}  
.bg---gray-200   { background: mat-color($gmb-darkgray, 200) }
.bg--dark--gray  { background: #575962;}

/*----------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------*/
.border--success {
  border-bottom: 2px solid mat-color($gmb-app-accent, default);
}
.border--error {
  border-bottom: 2px solid mat-color($gmb-app-warn, default);
}
.border--undefined {
  border-bottom: 2px solid #575962;
}

/*----------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------*/
.icon--alert {
  border: 2px solid mat-color($gmb-orange, 500);
}

/*----------------------------------------------------------------------------*/
/*#BORDER STYLE*/
/*----------------------------------------------------------------------------*/
.border--radius-50 { border-radius: 50%; }
.border--radius-25 { border-radius: 25px!important; }
.border--radius-3 { border-radius: 3px; }


.border-bottom  { border-bottom: 1px solid $lightgray; }
.border-left    { border-left: 1px solid $lightgray; }
.border-right   { border-right: 1px solid $lightgray; }
.border-top     { border-top: 1px solid $lightgray; }

.border--dashed { border: 1px dashed; }
.border--darkgray { border-color: #73737359; }
.border--blue { border-color: mat-color(mat-palette($gmb-blue, 100)); }
.border--black { border: 1px solid black!important; }

.border--solid { border: 1px solid; }
.border--gray { border-color: #eeeff2; }

.border--red { border: solid 1px red; }

/*----------------------------------------------------------------------------*/
/*#CONTAINER STYLE*/
/*----------------------------------------------------------------------------*/

[class^='container'] {
  width: 100%;
  padding-left:  15px;
  padding-right: 15px;
  margin-left:  auto;
  margin-right: auto;
}
.container--full { max-width: 100%; }
.container       { max-width: 1440px; }
.container--lg   { }
.container--md   { max-width: 760px; }
.container--sm   { max-width: 90%; }
.container--xs   { max-width: 360px; }
.container--75   { max-width: 75%!important; }
.container--65   { max-width: 65%!important; }


.mw--xxs { max-width: 20px; }

.w--auto { width: auto; }
.w--4 { width: 4%; }
.w--5{ width: 5%; }
.w--7 { width: 7%; }
.w--8 { width: 8%; }
.w--8-5 { width: 8.5%; }
.w--10 { width: 10% !important; }
.w--15 { width: 15% !important;}
.w--16 { width: 16%; }
.w--17 { width: 17%; }
.w--18 { width: 18%!important; }
.w--20 { width: 20%!important; }
.w--25 { width: 25%!important; }
.w--30 { width: 30%!important; }
.w--33 { width: 33% !important; }
.w--40 { width: 40%!important; }
.w--50 { width: 50%; }
.w--55 { width: 55%!important; }
.w--60 { width: 60%; }
.w--65 { width: 65%; }
.w--66 { width: 66% !important; }
.w--70 { width: 70%!important; }
.w--75 { width: 75%!important; }
.w--80 { width: 80%; }
.w--82 { width: 82%!important; }
.w--83 { width: 83%!important; }
.w--85 { width: 85%; }
.w--86 { width: 86%!important; }
.w--88 { width: 88% !important;}
.w--94  { width: 94%; }
.w--90  { width: 90%; }
.w--96  { width: 96%; }
.w--100 { width: 100%!important; }

.w--xxs { width: 30px; }
.w--sm  { width: 230px; }
.w--md  { width: 350px; }
.w--lg  { width: 600px; }

.h--auto { height: auto!important; }
.hp--20 { height: 20%; }
.hp--100 { height: 100%; }

.vh--24 { height: 24vh;}
.vh--46 { height: 46vh;}

.h--4 { height: 4%; }
.h--8 { height: 8%; }
.h--80 { height: 80% !important; }

.h--15 { height: 15px!important; }
.h--20 { height: 20px!important; }
.h--45 { height: 45px!important; }
.h--100 { height: 100px; }
.h--150 { height: 150px; }
.h--200 { height: 200px; }
.h--220 { height: 220px; }
.h--280 { height: 280px; }

/*----------------------------------------------------------------------------*/
/*# WIDTH PIXELS */
/*----------------------------------------------------------------------------*/
.w--15-px { width: 15px !important; }
.w--20-px { width: 20px !important; }
.w--50-px { width: 50px !important; }
.w--130-px { width: 130px !important; }
.w--165-px { width: 165px !important; }
.w--190-px { width: 190px !important; }
.w--200-px { width: 200px !important; }
.w--300-px { width: 300px !important; }
.w--500-px { width: 500px !important; }


/*----------------------------------------------------------------------------*/
/*# MAX WIDTH PIXELS */
/*----------------------------------------------------------------------------*/
.m-w--200-px { width: 200px !important; }

/*----------------------------------------------------------------------------*/
/*#PADDING STYLE*/
/*----------------------------------------------------------------------------*/

.container--padding-lg { padding: 40px 30px 80px; }
.container--padding-rl-lg {
  padding-left: 30px;
  padding-right: 30px;
}

.col--padding {
  padding-top:    15px;
  padding-bottom: 15px;
}

.col--padding-lg {
  padding-top:    40px;
  padding-bottom: 20px;

  @media (max-width: $screen-xl) {
    padding-top: 10px;
    padding-bottom: 0;
  } //register screen
}
.col--padding-xl {
  padding-top:   115px;
  padding-bottom: 20px;
}

.p--0  { padding: 0!important; }
.p--6  { padding: 6px; }
.p--5 { padding: 5px; }
.p--10 { padding: 10px!important; }
.p--12 { padding: 12px; }
.p--15 { padding: 15px!important; }
.p--20 { padding: 20px!important; }
.p--25 { padding: 25px; }
.p--30 { padding: 30px!important; }

.pb--0 { padding-bottom: 0!important; }
.pb--5 { padding-bottom: 5px!important; }
.pb--10 { padding-bottom: 10px!important; }
.pb--15 { padding-bottom: 15px!important; }
.pb--20 { padding-bottom: 20px!important; }
.pb--30 { padding-bottom: 30px!important; }
.pb--40 { padding-bottom: 40px!important; }
.pb--80 { padding-bottom: 80px!important; }

.pt--0 { padding-top: 0!important; }
.pt--5 { padding-top: 5px!important; }
.pt--10 { padding-top: 10px!important; }
.pt--15 { padding-top: 15px!important; }
.pt--20 { padding-top: 20px!important; }
.pt--30 { padding-top: 30px!important; }
.pt--40 { padding-top: 40px!important; }
.pt--67 { padding-top: 67px!important; }
.pt--80 { padding-top: 80px!important; }

.pr--0 { padding-right: 0!important; }
.pr--5 { padding-right: 5px; }
.pr--10 { padding-right: 10px!important; }
.pr--15 { padding-right: 15px; }
.pr--20 { padding-right: 20px; }
.pr--30 { padding-right: 30px!important; }
.pr--35 { padding-right: 35px!important; }
.pr--40 { padding-right: 40px!important; }
.pr--45 { padding-right: 45px!important; }
.pr--50 { padding-right: 50px!important; }
.pr--60 { padding-right: 60px!important; }
.pr--100 { padding-right: 100px!important; }

.pl--0 { padding-left: 0!important; }
.pl--2 { padding-left: 2px; }
.pl--4 { padding-left: 4px; }
.pl--5 { padding-left: 5px; }
.pl--6 { padding-left: 6px!important; }
.pl--10 { padding-left: 10px!important; }
.pl--12 { padding-left: 12px!important; }
.pl--15 { padding-left: 15px; }
.pl--20 { padding-left: 20px!important; }
.pl--25 { padding-left: 25px!important; }
.pl--30 { padding-left: 30px!important; }
.pl--35 { padding-left: 35px; }
.pl--40 { padding-left: 40px!important; }
.pl--45 { padding-left: 45px!important; }
.pl--60 { padding-left: 60px!important; }



.p-tb--0 {
  padding-top:    0!important;
  padding-bottom: 0!important;
}
.p--tb-2 {
  padding-top:    2px!important;
  padding-bottom: 2px!important;
}
.p--tb-10 {
  padding-top:    10px!important;
  padding-bottom: 10px!important;
}
.p--tb-14 {
  padding-top:    14px!important;
  padding-bottom: 14px!important;
}
.p--tb-20 {
  padding-top:    20px!important;
  padding-bottom: 20px!important;
}
.p--tb-30 {
  padding-top:    30px!important;
  padding-bottom: 30px!important;
}

.p--lr-0 {
  padding-left:  0!important;
  padding-right: 0!important;
}
.p--lr-5 {
  padding-left: 5px;
  padding-right: 5px;
}
.p--lr-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.p--lr-14 {
  padding-left: 14px!important;
  padding-right: 14px!important;
}
.p--lr-15 {
  padding-left: 15px!important;
  padding-right: 15px!important;
}
.p--lr-20 {
  padding-left: 20px!important;
  padding-right: 20px!important;
}
.p--lr-30 {
  padding-left: 30px!important;
  padding-right: 30px!important;
}

.locations-no-padding .p--30 { padding: 0; }

/*----------------------------------------------------------------------------*/
/* Z-INDEX */
/*----------------------------------------------------------------------------*/
.z-index--auto { z-index: auto!important; }
.z-index--0 { z-index: 0!important; }
.z-index--9 { z-index: 9; }
.z-index--999 { z-index: 999!important; }
.z-index--9999 { z-index: 9999!important; }
/*----------------------------------------------------------------------------*/
/*#MARGIN STYLE*/
/*----------------------------------------------------------------------------*/

.m--0   { margin: 0!important; }
.m--4   { margin: 4px!important; }
.m--10  { margin: 10px!important; }
.m--15  { margin: 15px!important; }
.m--20  { margin: 20px!important; }
.m--30  { margin: 30px!important; }

.mb--0  { margin-bottom: 0!important; }
.mb--2  { margin-bottom: 2px!important; }
.mb--5  { margin-bottom: 5px!important; }
.mb--10 { margin-bottom: 10px!important; }
.mb--25 { margin-bottom: 25px!important; }
.mb--50 { margin-bottom: 50px!important; }

.mb--12 { margin-bottom: 12px!important; }
.mb--15 { margin-bottom: 15px!important; }
.mb--20 { margin-bottom: 20px!important; }
.mb--30 { margin-bottom: 30px!important; }
.mb--40 { margin-bottom: 40px!important; }
.mb--45 { margin-bottom: 45px!important; }
.mb--50 { margin-bottom: 50px!important; }
.mb--60 { margin-bottom: 60px!important; }
.mb--90 { margin-bottom: 60px!important; }

.mb--27 { margin-bottom: 27px!important; }

.ml--auto { margin-left: auto!important; }
.ml--0 { margin-left: 0px!important; }
.ml--3  { margin-left: 3px!important; }
.ml--5  { margin-left: 5px!important; }
.ml--10 { margin-left: 10px!important; }
.ml--15 { margin-left: 15px!important; }
.ml--20 { margin-left: 20px!important; }
.ml--25 { margin-left: 25px!important; }
.ml--30 { margin-left: 30px!important; }
.ml--35 { margin-left: 35px!important; }
.ml--45 { margin-left: 45px!important; }
.ml--290 { margin-left: 290px!important; }

.mr--0  { margin-right: 0!important; }
.mr--5  { margin-right: 5px!important; }
.mr--8 { margin-right: 8px!important; }
.mr--10 { margin-right: 10px!important; }
.mr--12 { margin-right: 12px!important; }
.mr--15 { margin-right: 15px!important; }
.mr--18 { margin-right: 18px!important; }
.mr--20 { margin-right: 20px!important; }
.mr--25 { margin-right: 25px!important; }
.mr--35 { margin-right: 35px!important; }
.mr--45 { margin-right: 45px!important; }
.mr--90 { margin-right: 90px!important; }

.mt--0  { margin-top: 0px!important; }
.mt--5  { margin-top: 5px!important; }
.mt--10 { margin-top: 10px!important; }
.mt--15 { margin-top: 15px!important; }
.mt--20 { margin-top: 20px!important; }
.mt--25 { margin-top: 25px!important; }
.mt--30 { margin-top: 30px!important; }
.mt--35 { margin-top: 35px!important; }
.mt--40 { margin-top: 40px!important; }
.mt--50 { margin-top: 50px!important; }
.mt--60 { margin-top: 60px!important; }
.mt--65 { margin-top: 65px!important; }
.mt--75 { margin-top: 75px!important; }
.mt--90 { margin-top: 90px!important; }
.mt--120 { margin-top: 120px!important; }

.mt--auto { margin-top: auto!important; }

.m-tb--0 {
  margin-top:    0;
  margin-bottom: 0;
}

.m--auto {
  margin-left:  auto!important;
  margin-right: auto!important;
}

.last-no-margin p:last-child { margin-bottom: 0!important; }


.register--margin {
  margin-bottom: 30px;
  @media (max-width: $screen-xl) {

    margin-bottom: 0;

    &.box--padding-md { padding: 40px 10px;}
    &.box--padding-xxs { padding: 20px 15px;}
  }
}

.row.gutters-xs {
  margin-left:  -3px;
  margin-right: -3px;

  [class*="col-"] {
    padding-left:  3px;
    padding-right: 3px;
  }
}
.row.gutters-xsm {
  margin-left:  -5px;
  margin-right: -5px;

  [class*="col-"] {
    padding-left:  5px;
    padding-right: 5px;
  }
}
.row.gutters-sm {
  margin-left:  -10px;
  margin-right: -10px;

  [class*="col-"] {
    padding-left:  10px;
    padding-right: 10px;
  }
}

/*----------------------------------------------------------------------------*/
/*#JUSTIFY CONTENT*/
/*----------------------------------------------------------------------------*/
.j--content--end { justify-content: end!important; }
.j--content--center { justify-content: center!important; }
/*----------------------------------------------------------------------------*/
/*#DISPLAY*/
/*----------------------------------------------------------------------------*/
.d--block { display: block!important; }
.d--inl-block { display: inline-block!important; }
.d--inl-grid { display: inline-grid; }
.d--none { display: none!important; }
.d--contents { display: contents!important; }

/*----------------------------------------------------------------------------*/
/*#TEXT ALIGN*/
/*----------------------------------------------------------------------------*/
.txt--align--end { text-align: end; }
.txt--align--center { text-align: center; }

/*----------------------------------------------------------------------------*/
/*#VERTICAL ALIGN*/
/*----------------------------------------------------------------------------*/
.vertical--align--top { vertical-align: top!important; }
.vertical--align--middle { vertical-align: middle; }
.vertical--align--bottom { vertical-align: bottom; }
.vertical--align--super { vertical-align: super; }
.vertical--align--end { vertical-align: end; }
.vertical--align--sub { vertical-align: sub; }

/*----------------------------------------------------------------------------*/
/*#IMAGE STYLE*/
/*----------------------------------------------------------------------------*/
.object-fit-contain { object-fit: contain; }

.img--fluid { max-width: 100%; }

.img--round {
  border-radius: 50px;
  overflow: hidden;
}

.img--center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img--xxs { max-width: 23px; }
.img--xs { max-width: 50px; }
.img--sm { max-width: 80px; }
.img--md { max-width: 120px; }
.img--lg { max-width: 150px; }

.img--height-md { max-height: 140px; }

.reverse-icon { transform: scaleX(-1); }

/*----------------------------------------------------------------------------*/
/*#UNIQUE HELPERS*/
/*----------------------------------------------------------------------------*/

.screen-height { min-height: 100vh; }

.cursor--pointer { cursor: pointer!important; }

.border-box { box-sizing: border-box; }

.no-shadow { box-shadow: none!important; }

.flex-grow-1 { flex-grow: 1; }

.flex-shrink-0 { flex-shrink: 0; }

/*----------------------------------------------------------------------------*/
/*MIN HEIGHT*/
/*----------------------------------------------------------------------------*/
.min--height--0 { min-height: 0!important; }
.min--height--20 { min-height: 19px;}
.height--m { min-height: 96px;}

/*----------------------------------------------------------------------------*/
/*MIN WIDTH*/
/*----------------------------------------------------------------------------*/
.min--width--0 { min-width: 0!important; }
.min--width--100 { min-width: 100px!important; }

/*----------------------------------------------------------------------------*/
/*DISPLAY*/
/*----------------------------------------------------------------------------*/
.display-in-bl { display: inline-block !important;}
.display--block { display: block !important;}

/*----------------------------------------------------------------------------*/
/*#BORDER RADIUS*/
/*----------------------------------------------------------------------------*/
.br--5 { border-radius: 5px!important; }
.br--8 { border-radius: 8px!important; }
.br--10 { border-radius: 10px!important; }
.br--50 { border-radius: 50px!important; }

/*--------position--------------------*/
.position--absolute {
  position: absolute !important;
}
.position--relative {
  position: relative !important;
}

/*--------flex directions--------------------*/
.flex-direc-row-reverse { flex-direction: row-reverse!important; }

/*--------gap sizes--------------------*/
.gap--5 { gap: 5px !important; }
.gap--10 { gap: 10px !important; }
.gap--20 { gap: 20px !important; }
.gap--30 { gap: 30px !important; }


/*----------Stepper Hide Header Globally-------------------*/
.mat-horizontal-stepper-header-container {
  display: none !important;
}

/*----------Table-------------------*/
.highlight-cell-yellow { 
  border-bottom: 1px solid rgba(251, 188, 2, 0.05);
  background: rgba(251, 188, 2, 0.05);
}

.highlight-cell-blue { 
  border-bottom: 1px solid #DEDEDE;
  background: #BFE2FC;
}

/*----------List Style-------------------*/
.list-style-disc { list-style: disc!important; }


/*----------Field Disabled-------------------*/
.field-disabled { 
  color: #6a6a6c!important;
  background: #eeeff3!important;
  border: 1px solid #e6e6e6 !important;
}