
/*----------------------------------------------------------------------------*/
/*#PROGRESS BAR STYLE*/
/*----------------------------------------------------------------------------*/

//progress bar with steps
.progress-bar {
  @extend .d-flex;
  @extend .align-items-center;
  height: 100px;
  position: relative;
  margin-bottom: 20px;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer,
.mat-progress-bar.mat-primary .mat-progress-bar-buffer { background-color: #eeeff3; }

.steps {
  display: flex;
  justify-content: space-between;
  position: absolute;
  left:   -50px;
  right:  -50px;
  bottom: -2px;
  counter-reset: stepsNumber;

  &__item {
    color: #c0c0c1;
    transition: .3s ease-in;
    transition-delay: .1s;
    width: 120px;
    font-size: 13px;
    line-height: 1.1;
    text-transform: uppercase;
    text-align: center;

    &::before {
      counter-increment: stepsNumber;
      content: counter(stepsNumber);
      display: block;
      margin: 0 auto 10px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      font-size: 16px;
      line-height: 30px;
      text-align: center;
      @extend .txt--gray;
      background-color: #eeeff3;
      transition: .1s ease-in;
      transition-delay: .2s;
    }

    &--complete {
      color: #1c1c29;
      &.steps__item::before {
        @extend .bg--green;
        color: #fff;
      }

    }
  }
}

//progress bar with text inside
.progress-label {
  text-align: end;


  //change progress bar depending on chart colors
  &.progress--blue {
    .mat-progress-bar-fill::after { background-color: rgb(66, 133, 244); }
  }
  &.progress--yellow {
    .mat-progress-bar-fill::after { background-color: rgb(251, 188, 5); }
  }
  &.progress--green {
    .mat-progress-bar-fill::after { background-color: rgb(52, 168, 83); }
  }
  &.progress--red {
    .mat-progress-bar-fill::after { background-color: rgb(234, 67, 53); }
  }

  &.progress-label--normal {
    width: 100%;

    .mat-progress-bar {
      border-radius: 3px;
      .mat-progress-bar-fill::after { border-radius: 0; }
    }
  }


  .mat-progress-bar {
    height: 20px;
    border-radius: 10px;

    .mat-progress-bar-fill::after { border-radius: 10px; }
  }

  &__txt {
    position: relative;
    bottom: 20px;
    margin-right: 10px;

    &.txt--white { color: #fff; }
    &.txt--black { color: #000; }
  }
}

.progress--vertical {

  .row { height: 100%; }

  .box__content { height: calc(100% - 65px); }

  .progress-wrapper {
    position: relative;
    height: 200px;
    width: 100%;
    padding-top: 30px!important;
  }
  .progress--absolute {
    position: absolute;
    left: 50%;
    transform: translateX(-70px);
  }

  .progress-label__txt {
    position: relative;
    left: inherit;
    top: inherit;
    transform: none;
    color:#000000!important;
  }
  .progress-label {
    width: 180px;
    transform: rotate(270deg) translateX(-60px) translateY(-25px);
  }
}

.box--height .box--height { height: 100%; }
