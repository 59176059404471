
/*----------------------------------------------------------------------------*/
/*#BUTTON STYLE*/
/*----------------------------------------------------------------------------*/

.btn,
[class*="mat-"].btn {
  position: relative;
  transition: all .3s ease-in-out;
  text-align: center;
  line-height: 1!important;
  cursor: pointer;
  font-weight: 400;

  &--blue {
    background-color: mat-color($gmb-app-primary, 50);
    color: mat-color($gmb-app-primary, default);
  }
  &--lightblue {
    background-color: mat-color($gmb-blue, 100);
    color: mat-color($gmb-blue, 600);
  }
  &--orange {
    background-color: #fdefda!important;
    color: #f3a637;
  }
  &--green {
    background-color: mat-color($gmb-app-accent, 50)!important;
    color: mat-color($gmb-app-accent, default);
  }
  &--default {
    background-color: mat-color($gmb-gray, 700);
    color: mat-color($gmb-gray, 500);
  }
  &--red {
    background-color: mat-color($gmb-app-warn, 50)!important;
    color: mat-color($gmb-app-warn, default);
  }
  &--white {
    background-color: #fff;
    color: #000;
  }

  &--reset {
    background-color: transparent;
    border: none;
    padding: 0;
    &:hover { color: mat-color($gmb-app-primary, 500); }
  }

  svg {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }

  i:not([class*='mr']) { margin-right: 10px; }

  &--rounded { border-radius: 50px!important; }

  &--icon, &--icon .mat-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--between .mat-button-wrapper {
    width: 100%;
    justify-content: space-between;
  }

  &__txt--xs {
    font-size: 13px;
    max-width: 200px;
    width: 100%;
  }

  &__txt--xxs { font-size: 11px; }


  &--center {
    margin-left: auto;
    margin-right: auto;
  }

  //remove opacity on focus
  .mat-button-focus-overlay {
    background-color: transparent;
    opacity: 1;
  }
  &.cdk-program-focused .mat-button-focus-overlay {
    background-color: transparent;
    opacity: 1;
  }
}

.link {
  cursor: pointer;
  transition: .3s ease-in-out;
  &--blue {
    color: #1b55c6;
    &:hover { color: #000; }
  }
  &--white {
    color: #9a9b9d!important;
    &:hover { color: #F3F3F3!important; }
  }
}

//button size
.btn--action,
[class*="mat-"].btn--action {
  min-width: 120px;
  font-size: 15px;
  line-height: 1;
  padding: 12px;

  &-sm {
    min-width: 100px;
    font-size: 14px;
    padding: 6px 12px;
  }
  &-xs {
    min-width: 80px;
    font-size: 14px;
    padding: 6px 12px;
  }
}

.btn--xxl,
[class*="mat-"].btn--xxl {
  font-size: 18px;
  line-height: 1;
  padding: 18px;
}

.btn--xl,
[class*="mat-"].btn--xl {
  max-width: 300px;
  width: 100%;
  font-size: 22px;
  line-height: 1;
  padding: 20px 10px;
}

.btn--lg,
[class*="mat-"].btn--lg {
  font-size: 16px;
  min-width: 250px;
  padding: 15px;

  &.btn--txt--lg {
    font-size: 18px;
    line-height: 1;
  }
}

.btn--md,
[class*="mat-"].btn--md {
  min-width: 180px;
  padding: 15px;
  line-height: 1;
}

.btn--sm,
[class*="mat-"].btn--sm {
  font-size: 14px;
  padding: 20px 0 10px;
}

.btn--xs,
[class*="mat-"].btn--xs {
  font-size: 14px;
  padding: 7px 18px;

  &--limited {
    padding-top:    10px;
    padding-bottom: 10px;
    min-width: 100px;
  }
}

.btn--fluid,
[class*="mat-"].btn--fluid {
  min-width: inherit;
}

.btn--full,
[class*="mat-"].btn--full {
  max-width: 100%;
  min-width: inherit;
  width: 100%;
}

[class*="mat-"].fw--regular { font-weight: 400; }

.btn--img {
  position: relative;
  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: calc(50% - 140px);
  }
}

[class*="mat-"].btn-icon--info {
  position: absolute;
  z-index: 2;
}
[class*="mat-"].btn-icon--remove {
  position: absolute;
  right: 20px;
  top: 5px;
  z-index: 2;
}

[class*="mat-"].btn-icon--plus {
  position: absolute;
  right: 55px;
  top: 5px;
  z-index: 2;
}


.btn-dialog-close,
.btn-delete {
   -webkit-appearance: none;
   border: none;
   background: transparent;
   position: absolute;
   top: 5px;
   right: 5px;
   color: #fff;

   i { @extend .m--0; }
 }

.btn-delete-full {
  -webkit-appearance: none;
  border: none;
  background: transparent;
  position: relative;
  top: 5px;
  right: 400px;
  color: #80ffb8;

  i { @extend .m--0; }
}

.btn--table-img {
  min-width: 140px;
  justify-content: center;
  padding: 10px 20px;

  img {
    max-width: 20px;
    width: 100%;
  }
}


.btn--badge,
[class*="mat-"].btn--badge {
  border-radius: 3px;

  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-height { height: 25px; }

  &--sm {
    min-width: 95px;
    padding: 5px 10px;
    font-size: 10px;
    line-height: 1;
    margin: 5px 0;
  }
  &--lg {
    min-width: 150px;
    padding: 13px 20px;
    font-size: 12px;
  }

  i { margin-right: 5px }
}

.btn-icon,
.mat-flat-button.btn-icon {
  width:  auto;
  min-width: 30px;
  height: 30px;
  padding: 5px 8px;
  border-radius: 3px;
  line-height: 1;

  i:not([class*='mr']),
  svg { margin-right: 0; }

  img {
    display: inline-block;
    max-width: 20px;
    max-height: 15px;
  }

  &.btn-icon--txt {
    font-size: 20px;
    line-height: 1;
  }

  &--share {
    min-width: 50px!important;
    height: 50px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-top: -1px;

    i { font-size: 20px; }
  }

  &--xl {
    min-width: 50px!important;
    height: 25px;

    img { max-height: 15px; }
  }

  &--lg {
    min-width: 40px!important;
    height: 40px;
  }

  &-sm {
    min-width: 30px!important;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    img {
      display: inline-block;
      max-width: 20px;
      max-height: 15px;
    }
  }

  &--xs {
    min-width: 30px!important;
    height: 20px;
  }

  &--xxs {
    min-width: 25px;
    height: 25px;
  }

  &--download {
    min-width: 46px!important;
    height: 46px;
  }

  &--billing {
    min-width: 38px!important;
    height: 38px;
    padding: 5px;

    svg { margin-right: 0; }
  }

  &--gray {
    i { color: #737373; }
  }

  &--blue {
      color: #0060CD;
  }

  &--red {  
      color: #D54238;
  }
  
}

.btn--width-sm {
  max-width: 190px;
  width: 100%;
}

.btn--flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn--underline-blue {
  color: mat-color($gmb-app-primary, default);
  &:hover { text-decoration: underline; }
}

.btn--underline-gray { color: #7d7d7d; }

.btn-outline-transparent {
  border: 1px solid #eeeff3!important;
  border-radius: 5px!important;

  &:hover {
    background: #f5f5f5;
  }
}

.btn-pull {
  &--right {
    margin-left: auto;
      order: 2;
  }
}

.mat-button.btn-cancel {
  position: relative;
  color: #7d7d7d!important;
  padding: 0;
  min-width: inherit;

  &--white {
    color: #fff!important;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #7d7d7d;
  }

  .mat-button-focus-overlay { background-color: transparent!important; }
}

.mat-flat-button.btn-txt--red { color: red!important; }
.mat-flat-button.btn-txt--blue { color: mat-color($gmb-app-primary, 500)!important; }
.mat-flat-button.mat-red-alert { background: #ff4a4a !important; color: white !important }


.tag-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;

  .tag {
    display: flex;
    justify-content: flex-start;
    border-radius: 3px;
    overflow: hidden;
    margin-bottom: 5px;
    margin-right: 5px;

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width:  35px;
      height: 35px;
      color: #fff;
      flex-shrink: 0;

      i { font-size: 13px; }
    }
    &__content {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.6;
      padding: 8px 10px 8px 28px;
      flex-grow: 1;
      max-height: 35px;
      overflow: hidden;

      .mat-standard-chip { min-height: 19px; }
    }

    &--success {
      background-color: mat-color($gmb-green, 50);
      .tag__icon { background-color: mat-color($gmb-green, 900); }
    }
    &--error {
      background-color: mat-color($gmb-red, 50);
      .tag__icon { background-color: mat-color($gmb-red, 900); }
    }
  }
}

.chip--sm {
  .mat-standard-chip {
    padding: 5px 9px;
    min-height: 25px;
    font-size: 13px;
  }
}

.tag-divider {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px;
    background: mat-color($gmb-gray, 500);
  }
}

.button-toggle-group {

  &--sm {
    .mat-button-toggle {
      width: 100px;
    }

    &.mat-button-toggle-group-appearance-standard .mat-button-toggle-label-content {
      padding: 5px 15px;
      font-size: 16px;
      line-height: 1;
    }
  }

  .mat-button-toggle {
    background-color: #fff;
    color: #000;
    width: 100px;
  }

  .mat-button-toggle-checked {
    background-color: mat-color($gmb-app-primary);
    color: mat-color($gmb-app-primary, default-contrast);
  }

  .padding-buttons {
    padding: 10px 40px!important;
  }
}
