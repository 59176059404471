/*----------------------------------------------------------------------------*/
/*#INPUT STYLE*/
/*----------------------------------------------------------------------------*/

.input-field {
  position: relative;
  width: 100%;

  &--uppercase {
    .mat-form-field-label {
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  &--search {
    width: auto;
    display: flex;
    align-items: center;
    flex-grow: 1;
    color: #737373;

    svg {
      width: 20px;
      height: 20px;
    }

    input {
      border: none;
      width: 100%;
      padding: 20px;
      font-size: 14px;
    }
  }

  &-wrapper--search { width: 420px; }

  &--table-search {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    width: auto;
    padding: 10px;
    border-radius: 5px;
    background-color: #e6e7ec;
    i {
      color: #737373;
      margin-right: 5px;
    }
    input {
      border: none;
      background-color: transparent;
      font-size: 13px;
      font-weight: 700;
      width: 100%;

      &::placeholder { text-transform: uppercase; }
    }
  }

  &--header {
    position: relative;
    &::before {
      content: '\f002';
      font-family: "Font Awesome 5 Pro", sans-serif;
      font-size: 18px;
      font-weight: 700;
      position: absolute;
      left: 30px;
      top:  50%;
      transform: translateY(-50%);
      @extend .txt--gray;
    }
    input {
      height: $header-height;
      padding-left: 70px;
    }
  }

  &--pagination {
    display: flex;
    align-items: center;
    width: auto;
    label {
      font-size: 14px;
      margin-right: 15px;
    }
    input {
      width: 50px;
      height: 40px;
      border: 1px solid $lightgray;
      border-radius: 5px;
      padding: 10px 5px;
      text-align: center;
    }
  }

  &--image {
    width: 130px;
    height: 130px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    border: 1px solid $lightgray;

    .spinner-wrapper { min-height: 130px; }

    &--fluid {
      width:  100%;
      height: 208px;
      flex-shrink: 0;
      margin-right: 0;
      .spinner-wrapper { min-height: 208px; }

      .file-preview {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    &--lg {
      width:  250px;
      height: 250px;
      flex-shrink: 0;
      margin-right: 20px;

      .input-field--image { height: 250px; }

      .spinner-wrapper { min-height: 250px; }

      .file-preview {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    label {
      position: absolute;
      top:    0;
      left:   0;
      right:  0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}

.activate-hover {

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-color: rgba(0,0,0,.3);
    opacity: 0;
    transition: .3s ease-in-out;
  }

  .preview-text {
    position: absolute;
    bottom: -30px;
    transition: .3s ease-in-out;
    z-index: 2;
    span {
      color: #fff!important;
    }
  }

  &:hover {
    &::before { opacity: 1; }
    .preview-text { bottom: 40%; }
  }
}

.input-field--image--fluid .activate-hover {
  .preview-text {
    bottom: -100px;
  }

  &:hover {
    .preview-text { bottom: 30%; }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance:textfield;
}

input[type=number] {
  width: 100%;
  border: 1px solid $lightgray;
  border-radius: 5px;
  padding: 15px 25px 15px 15px;
  font-size: 14px;
  font-weight: 700;
}

.input-number-wrapper {
  position: relative;
  max-width: 100px;
  &--center {
    margin-left: auto;
    margin-right: auto;
  }

  &--sm {

    input[type=number] { padding: 10px 20px 10px 15px; }
    .input-number--arrows {
      width: 20px;
      .arrow {
        padding: 0 5px;
        i { font-size: 13px; }
      }
    }
  }
}

.input-number--arrows {
  position: absolute;
  top:    0;
  right:  0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 25px;
  border-left: 1px solid $lightgray;

  .arrow {
    cursor: pointer;
    width: 100%;
    padding: 2px 5px;
    @extend .txt--lightgray;
    transition: all .3s ease-in-out;

    &:hover { color: #000; }

    &:first-child {
      border-bottom: 1px solid $lightgray;
    }
  }
}

.input--email,
.mat-input-element.input--email    { background: url('assets/images/icons/mail.png') no-repeat 15px center; }
.input--password,
.mat-input-element.input--password { background: url('assets/images/icons/key.png') no-repeat 15px center; }
.input--user,
.mat-input-element.input--user     { background: url('assets/images/icons/user.png') no-repeat 15px center; }
.input--company,
.mat-input-element.input--company  { background: url('assets/images/icons/company.png') no-repeat 15px center; }
.input--calendar,
.mat-input-element.input--calendar { background: url('assets/images/icons/calendar.png') no-repeat 15px center/16px; }
.input--clock,
.mat-input-element.input--clock { background: url('assets/images/icons/clock.png') no-repeat 15px center/16px; }

.input-icon--right,
.input-icon--right.mat-input-element {
  background-position: calc(100% - 10px) center;
}

.input-icon--left,
.input-icon--left.mat-input-element {
  background-position: calc(0% - -20px) center;
}

.input-group {
  position: relative;
  margin-bottom: 15px;
  width: 100%;

  &__label {
    display: block;
    font-size: 14px;
    line-height: 1;
    font-weight: 700;
    color: #1c1c29;
    margin-bottom: 10px;
  }

  &__field,
  .form-control {
    width: 100%;
    font-size: 16px;
    line-height: 1;
    padding: 15px;
    background-color: #fafafa;
    border: 1px solid #e6e6e6!important;
    border-radius: 3px;

    &.widget {
      display: block;
      padding: 15px;
      background-color: #EFF0F3;
      border-color: #EFF0F3;
      margin-bottom: 20px;
      width: 100%;
      font-family: monospace, monospace;
      font-size: 1em;
      line-height: 1.3;
      resize: none;
      min-height: 120px;
    }

    &.input-group__field--sm {
      font-size: 14px;
      padding: 10px 15px;
      background-color: #fff;

      &.mat-select {
        font-size: 14px!important;
        padding: 13px 15px!important;
        background-color: #fff;
      }
    }

    &[class*="input--"] { padding-left: 40px; }
  }

  &-addon button.dropbtn {
    display: inline-block;
    width: 50px;
    height: 51px;
    border-right: 1px solid #e6e6e6;
  }

  &.mat-form-field {
    .input-group__label { font-size: 19px; }

    .input-group__field {
      font-size: 16px;
      padding: 15px;
      background-color: #fafafa;
      border: 1px solid #e6e6e6;
      border-radius: 5px;
      &.no-borders-right {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .mat-form-field-infix { padding: 0.4375em 0 0; }
  }

  &--bordered {
    .input-group {
      display: flex;
      border: 1px solid #e6e6e6;
      border-radius: 5px;
      overflow: hidden;

      .form-control {
        background-color: #fff;
        border: none;
      }
    }
  }

  &.mat-form-field-appearance-legacy {
    .mat-form-field-subscript-wrapper {
      margin-top: 0;
      bottom: 0;
    }
    .mat-hint { margin-top: 4px; }
  }

  //override label animation of matInput
  &--override {
    margin-bottom: 0;

    &.mat-form-field {

      .mat-form-field-label-wrapper {
        left: 40px;
        top:   0;
        padding-top: 0;
      }

      .mat-form-field-label {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
      }

      //input
      .input-group__field {
        box-shadow: none;
        transition: .3s ease-in-out;
        background-color: #fff;
        padding-left: 40px;

        &.pl--20 { padding-left: 20px; }
      }

      &.mat-focused {
        .input-group__field {
          box-shadow:
            0 3px 1px -2px rgba(0, 0, 0, 0.2),
            0 2px 2px 0px rgba(0, 0, 0, 0.14),
            0 1px 5px 0px rgba(0, 0, 0, 0.12);
        }
      }

      .mat-form-field-infix {
        padding: 0;
        border-top: none;
      }

      &.mat-form-field-appearance-legacy.mat-form-field-can-float {
        .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label,
        &.mat-form-field-should-float .mat-form-field-label {
          transform: translateY(-1em) scale(0.75) perspective(100px) translateZ(0.001px);
          -ms-transform: translateY(-1em) scale(0.75);
          width: 133.33333%;
        }
      }
    }

    // all inputs should share the same style, but because we are only updating those in the login page, we need a temporary solution
    &-new {
      &.mat-form-field {
        .mat-form-field-label-wrapper {
          left:15px !important;
        }
        
        .input-group__field {
          background-color: #fafafa !important;
          padding-left: 10px !important;
        }
      }
    }

    &-label {
      &.mat-form-field {

        &.mat-focused .input-group__field { box-shadow: none; }

        .input-group__field { padding-left: 15px;  }

        .mat-form-field-label-wrapper {
          left: 0;
          label { padding-left: 15px; }
        }

        .mat-form-field-underline { display: none; }
      }
    }

    .input-group__field {
      &.input-group__field--xs {
        font-size: 14px;
        padding: 10px;
      }
    }
  }

  //override label animation of select
  &--select {
    .input-group__select {
      position: relative;
      padding: 10px 15px;
      font-size: 16px;
      background-color: #fff;
      border: 1px solid #e6e6e6;
      border-radius: 3px;

      ~ .mat-form-field-label-wrapper {
        position: relative;
        display: block;
        left: 0;
        top:  0;
        overflow: visible;
        padding-top: 0;

        label {
          position: relative;
          top: 0;
          font-size: 12px;
          font-weight: 600;
          margin-bottom: 10px;
        }
        mat-label { color: rgba(0, 0, 0, 0.54); }
      }
    }

    .mat-form-field-infix {
      display: flex;
      flex-direction: column-reverse;
      margin: 0;
      padding: 0!important;
      border-top: 0;

    }

    .mat-select-value { max-width: inherit; }


    &.mat-form-field-appearance-legacy {
      .mat-form-field-subscript-wrapper {
        z-index: -1;
      }
    }

    .mat-select-trigger { display: block; }

    .mat-select-arrow-wrapper {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .mat-select-arrow {
      position: relative;
      border: none;
      margin: 0;
      width: inherit;
      height: inherit;
      text-align: center;

      &::before {
        content: '\f107';
        font-family: "Font Awesome 5 Pro", sans-serif;
        font-weight: 300;
        font-size: 20px;
        line-height: 1;
      }
    }

    .mat-select-value {
      display: block;
      max-width: calc(100% - 10px);
    }

    .mat-form-field-underline { display: none; }

    //disable the animation
    &.mat-form-field-appearance-legacy.mat-form-field-can-float {
      .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label,
      &.mat-form-field-should-float .mat-form-field-label {
        transform: translateY(0.001px) scale(1) perspective(100px) translateZ(0.001px);
        -ms-transform: translateY(0.001px) scale(1);
        width: 100%;
      }
    }

    &-sm {
      width: 75px;

      .input-group__select {
        padding: 10px;
        width: 60px;
        margin-left: 15px;
      }

      .mat-select-arrow-wrapper {
        width: 15px;
        height: 20px;
      }

      .mat-select-arrow::before { font-size: 20px; }

    }
  }
}


.input-color-picker {
  position: absolute;
  right:  10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 1px solid #e6e6e6;
  pointer-events: none;
}

.mat-checkbox {
  .mat-checkbox-inner-container {
    width: 20px;
    height: 20px;
    margin-right: 20px;
  }

  &--square {
    background-color: transparent;
    width: 18px; height: 18px;
  }

  &--square--check {
    width: 6px;
    height: 12px;
    transform: rotate(45deg);
    margin: 0 auto;
  }

  &.checkbox--star {

    padding: 0 10px;

    &-md { padding: 5px; }

    &-sm { padding: 0 5px; }

    .mat-checkbox-inner-container {
      margin-right: 10px;
      @media (max-width: 1400px) { margin-right: 5px; }
    }
    .mat-checkbox-label {
      display: flex;
      align-items: center;
      img { margin: -3px 0 0 3px; }
    }
  }

  .mat-checkbox-layout,
  .mat-checkbox-label { width: 100%; }

  .mat-checkbox-frame {
    border-color: mat-color($gmb-gray, 900);
    border-radius: 4px;
    border-width: 1px;
  }
}

.checkbox-group {

  &--margin { margin: 0 -10px; }
  &--padding { padding: 20px 0 0; }

  .mat-checkbox {
    @extend .mat-checkbox, .checkbox--star;
  }
}

.checkbox-list {
  .mat-checkbox {
    display: inline-block;
    font-size: 14px;
    margin-bottom: 5px;
  }
}

//submit button disabled override
.mat-flat-button.mat-primary[disabled],
.mat-raised-button.mat-primary[disabled] {
  background-color: mat-color($gmb-gray, default);
  color: #fff;
}

mat-radio-button.mat-radio-button { padding: 0 10px; }

.input-label--angle {
  position: relative;

  &::before {
    content: '\f107';
    font-family: "Font Awesome 5 Pro", sans-serif;
    color: rgba(0, 0, 0, 0.54);
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 300;
    font-size: 20px;
    line-height: 1;
  }
}

.no-padding.mat-form-field-appearance-legacy .mat-form-field-wrapper { padding-bottom: 0; }

// .mat-select-panel {
//   max-height: 800px;
// }

