@import '../node_modules/@syncfusion/ej2-material-theme/styles/material.css';
@import "../node_modules/ag-grid-community/src/styles/ag-grid.scss";
@import "../node_modules/ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss";

// Reset CSS
@import './assets/scss/helpers/reset';

// Bootstrap Grid
@import './assets/scss/helpers/grid';

// Variables
@import "./assets/scss/helpers/variables";

//FontAwesome
@import "./assets/scss/fontawesome/fontawesome";
@import "./assets/scss/fontawesome/brands";
@import "./assets/scss/fontawesome/duotone";
@import "./assets/scss/fontawesome/light";
@import "./assets/scss/fontawesome/regular";
@import "./assets/scss/fontawesome/solid";

//create custom colors theme
@import '~@angular/material/theming';


@include mat-core();

//custom default theme (blue, green, red)
$gmb-app-primary: mat-palette($gmb-blue, 500);
$gmb-app-accent:  mat-palette($gmb-green, 500, 100, 900);
$gmb-app-warn:    mat-palette($gmb-red, 500);
$gmb-app-theme:   mat-light-theme($gmb-app-primary, $gmb-app-accent, $gmb-app-warn);
@include angular-material-theme($gmb-app-theme);

//custom alternate theme (gray, darkblue, orange)
.alternate-theme {
  $gmb-app-primary-alternate: mat-palette($gmb-gray, 500);
  $gmb-app-accent-alternate:  mat-palette($gmb-darkblue, 500, 100, 900);
  $gmb-app-warn-alternate:    mat-palette($gmb-orange, 500);
  $gmb-app-theme-alternate:   mat-light-theme($gmb-app-primary-alternate, $gmb-app-accent-alternate, $gmb-app-warn-alternate);
  @include angular-material-theme($gmb-app-theme-alternate);
}

//custom alternate theme (gray, darkblue, orange)
.alternate-dark-theme {
  $gmb-app-primary-alternate-dark: mat-palette($gmb-darkgray, 900);
  $gmb-app-accent-alternate-dark:  mat-palette($gmb-darkblue, 500, 100, 900);
  $gmb-app-warn-alternate-dark:    mat-palette($gmb-orange, 500);
  $gmb-app-theme-alternate-dark:   mat-light-theme($gmb-app-primary-alternate-dark, $gmb-app-accent-alternate-dark, $gmb-app-warn-alternate-dark);
  @include angular-material-theme($gmb-app-theme-alternate-dark);
}

.ag-theme-alpine {
  @include ag-theme-alpine();
}

// Header Style
@import './assets/scss/layout/header';

// Footer Style
@import './assets/scss/layout/footer';

// Sidebar Style
@import './assets/scss/layout/sidebar';

// Main Style
@import './assets/scss/layout/main';

// Button Style
@import './assets/scss/basics/buttons';

// Input Style
@import './assets/scss/basics/input';

// List Style
@import './assets/scss/basics/list';

// Lines Style
@import './assets/scss/basics/lines';

// Backgrounds Style
@import './assets/scss/basics/backgrounds';

// Table Style
@import './assets/scss/components/table';

// Box Style
@import './assets/scss/components/box';

// Filter Style
@import './assets/scss/components/filter';

// Messages Style
@import './assets/scss/components/messages';

// Dialog Style
@import './assets/scss/components/dialog';

// Panel Style
@import './assets/scss/components/panel';

// Reports Elements Style
@import './assets/scss/components/reports';

// Icons Style
@import './assets/scss/components/icons';

// Carousel Style
@import './assets/scss/components/carousel';

// Expansion Panel Style
@import './assets/scss/components/expansion-panel';

// Tooltip Style
@import './assets/scss/components/tooltip';

// Progress Bar Style
@import './assets/scss/components/progress-bar';

// Grade Style
@import './assets/scss/components/grader';

//Material
@import './assets/scss/components/material';

// Helpers CSS
@import './assets/scss/helpers/helpers';

// Responsive Style
@import './assets/scss/layout/responsive';

// Animation
@import './assets/scss/animation/animation';

.mat-toolbar {
  z-index: 2;
  top: 0;
  position: sticky;
}

#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
}

#text{
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: #000;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
}

// Syncfusion components style overrides
@import './assets/scss/syncfusion/syncfusion'