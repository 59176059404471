td.e-selected {
    span.e-day {
        background-color: #289ff6 !important;
        padding: 0px !important;
    }
}

.e-daterangepicker.e-popup .e-footer .e-btn,
.e-bigger.e-small .e-daterangepicker.e-popup .e-footer .e-btn {
    text-transform: capitalize !important;
}

.e-bigger.e-small .e-daterangepicker.e-popup .e-footer .e-btn {
    text-decoration: underline !important;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input).e-input-focus::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input).e-input-focus::after,
.e-input-group.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input).e-input-focus::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input).e-input-focus::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap::after {
    display: none !important;
}

.e-start-label,
.e-end-label {
    font-size: 14px !important;
}

.e-daterangepicker.e-popup .e-separator,
.e-bigger.e-small .e-daterangepicker.e-popup .e-separator {
    display: none;
}

.e-calendar .e-content.e-year td,
.e-calendar .e-content.e-decade td,
.e-bigger.e-small .e-calendar .e-content.e-year td,
.e-bigger.e-small .e-calendar .e-content.e-decade td {
    margin: 0px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 0px !important
}

.e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover {
    background-color: #289ff6 !important;
    border-radius: 0% !important;
    border-top-left-radius: 45% !important;
    border-bottom-left-radius: 45% !important;
    padding: 0px !important;
}

.e-daterangepicker.e-popup .e-calendar .e-end-date.e-selected.e-range-hover,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-end-date.e-selected.e-range-hover {
    background-color: #289ff6 !important;
    border-radius: 0% !important;
    border-top-right-radius: 45% !important;
    border-bottom-right-radius: 45% !important;
    padding: 0px !important;
}

.e-range-hover {
    background-color: #d0e3ed !important;

    span {
        background-color: #d0e3ed !important;
    }
}

.e-daterangepicker.e-popup .e-range-header .e-day-span {
    display: none;
}

.e-popup .e-calendar .e-header .e-title {
    color: #289ff6 !important;
    background-color: #ecf3fe !important;
    width: 66% !important;
    text-align: center;
    -webkit-box-shadow: inset 0px 6px 0px #fff, inset 0px -6px 0px #fff;
    -moz-box-shadow: inset 0px 6px 0px #fff, inset 0px -6px 0px #fff;
    box-shadow: inset 0px 6px 0px #fff, inset 0px -6px 0px #fff;
}

.e-daterangepicker.e-popup .e-presets .e-list-item {
    margin: 0px !important;
    font-size: 12px !important;
}

.e-daterangepicker.e-popup .e-presets .e-list-item.e-active {
    color: #289ff6 !important;
    background-color: #ecf3fe !important;
}

.e-daterangepicker.e-popup .e-range-header,
.e-bigger.e-small .e-daterangepicker.e-popup .e-range-header {
    border: none !important;
}

.e-daterangepicker.e-popup .e-calendar-container .e-left-container,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar-container .e-left-container {
    border: none !important;
}

.e-daterangepicker.e-popup .e-separator,
.e-bigger.e-small .e-daterangepicker.e-popup .e-separator {
    border: none !important;
}

.e-calendar .e-content.e-year td>span.e-day,
.e-calendar .e-content.e-decade td>span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-year td>span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade td>span.e-day {
    font-size: 16px;
}

// calendar container
.e-daterangepicker.e-popup .e-date-range-container.e-range-border,
.e-bigger.e-small .e-daterangepicker.e-popup .e-date-range-container.e-range-border {
    float: right;
    width: 500px;
}

// presets container
.e-daterangepicker.e-popup.e-preset-wrapper .e-presets,
.e-bigger.e-small .e-daterangepicker.e-popup.e-preset-wrapper .e-presets {
    float: left;
    width: 140px;
    border-right: 1px solid rgba(0, 0, 0, 0.12)
}


.e-daterangepicker.e-popup .e-presets .e-list-parent,
.e-bigger.e-small .e-daterangepicker.e-popup .e-presets .e-list-parent {
    margin-top: 20px !important;
}

.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
.e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
.e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
.e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap {
    border: 1px solid #e6e6e6 !important;
    padding: 4px;
    padding-left: 22px;
    font-size: 16px;
}

.e-btn.e-apply {
    color: white !important;
    background-color: #289ff6 !important;
}

.e-cancel {
    color: gray !important;
    text-decoration: underline !important;
}

.e-control input.e-input,
.e-control .e-input-group input,
.e-control .e-input-group input.e-input,
.e-control .e-input-group.e-control-wrapper input,
.e-control .e-input-group.e-control-wrapper input.e-input,
.e-control .e-float-input input,
.e-control .e-float-input.e-control-wrapper input,
.e-control.e-input-group input,
.e-control.e-input-group input.e-input,
.e-control.e-input-group.e-control-wrapper input,
.e-control.e-input-group.e-control-wrapper input.e-input,
.e-control.e-float-input input,
.e-control.e-float-input.e-control-wrapper input {
    font-size: 14px !important;
}